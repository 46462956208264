import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../global-shared/core/services/language.service';
import { AppService } from '../../../global-shared/core/services/app.service';
import { GoogleAnalyticsService } from "projects/global-shared/shared/modules/google-analytics/google-analytics.service";
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        public app: AppService,
        private languageService: LanguageService,
        private analyticsService: GoogleAnalyticsService
    ) {}

    public ngOnInit(): void {
        this.languageService.setBrowserLang();
        this.analyticsService.setupAnalytics(environment);
    }
}
