import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppTranslateLoader } from './app-translate.loader';
import { TranslateFakeLoader, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderModule } from './shared/components/loader/loader.module';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { KeycloakService } from 'keycloak-angular';
import { initializer } from '../../../global-shared/core/auth.init';
import { CoreModule } from '../../../global-shared/core/core.module';
import { AppErrorHandler } from './app-error.handler';
import { ErrorModule } from './error/error.module';
import { NotificationModule } from '../../../global-shared/shared/services/notification/notification.module';
import { NotFoundModule } from './not-found/not-found.module';
import { RouteReuseStrategy } from '@angular/router';
import { RoutingStrategy } from './app-routing.strategy';
import { GoogleAnalyticsModule } from 'projects/global-shared/shared/modules/google-analytics/google-analytics.module';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    ErrorModule,
    NotFoundModule,
    LoaderModule,
    NotificationModule,
    GoogleAnalyticsModule,
    NgxsModule.forRoot([], {
      developmentMode: !environment.production
    }),
    NgxsResetPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: environment.production || environment.useTranslations
          ? AppTranslateLoader : TranslateFakeLoader
      },
      defaultLanguage: environment.language
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: RouteReuseStrategy,
      useClass: RoutingStrategy
    }
  ]
})
export class AppModule {
}
